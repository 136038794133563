@tailwind base;
@tailwind components;
@tailwind utilities;

// vendors
@import "react-perfect-scrollbar/dist/css/styles.css";
@import "leaflet/dist/leaflet.css";
@import "leaflet.markercluster/dist/MarkerCluster.css";
@import "leaflet.markercluster/dist/MarkerCluster.Default.css";
@import "leaflet-minimap/dist/Control.MiniMap.min.css";

html,
body {
	@apply h-full;
}

.nav-panel {
	@apply absolute z-[1001] bg-white bg-opacity-75 top-0 bottom-0 max-w-full w-[27rem] pl-12 shadow-md overflow-auto;
}

/**
 Leaflet
 */

.leaflet-control-minimap {
	@apply shadow-lg;
}

/**
 Modal
 */
.modal {
	p {
		@apply my-3 last:m-0;
	}
}
